import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["chargeOnDateCheckbox", "chargeOnJoinCheckbox", "chargeOnDateCollapse"];

  toggle(event) {
    if (event.target == this.chargeOnDateCheckboxTarget) {
      this.chargeOnJoinCheckboxTarget.checked = !event.target.checked;
    } else {
      this.chargeOnDateCheckboxTarget.checked = !event.target.checked;
    }

    $(this.chargeOnDateCollapseTarget).collapse(this.chargeOnDateCheckboxTarget.checked ? "show" : "hide");
  }
}
