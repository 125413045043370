import {Controller} from "stimulus";

export default class extends Controller {
  static values = {
    url: String,
    selectedId: Number,
    selectedText: String
  }

  connect() {
    const config = {
      placeholder: "Select a page...",
      url: this.urlValue
    }

    if (this.hasSelectedIdValue) {
      config.initialSelection = {
        id: this.selectedIdValue,
        text: this.selectedTextValue
      }
    }

    $(this.element).alchemyPageSelect(config);
  }
}
