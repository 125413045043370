
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["initialQuantity", "shoppingListQuantity", "expectedQuantity"];

  changed() {
    const initialQuantity = parseInt(this.initialQuantityTarget.value);
    const shoppingListQuantity = parseInt(this.shoppingListQuantityTarget.innerHTML);

    if (isNaN(initialQuantity) || isNaN(shoppingListQuantity)) {
      return;
    }

    const expectedQuantity = initialQuantity - shoppingListQuantity;

    let pill;
    if (expectedQuantity == 0) {
      pill = "complete";
    } else {
      pill = expectedQuantity > 0 ? "success" : "warning"
    }

    const target = this.expectedQuantityTarget;
    target.classList.remove(...target.classList);
    target.classList.add("pill", `pill-${pill}`);
    target.innerHTML = expectedQuantity;
  }
}
