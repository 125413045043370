import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["map"];
  static values = {
    center: Object,
    polygon: Array,
    customers: Array
  };

  connect() {
    const googleMap = new window.google.maps.Map(
      this.mapTarget,
      {
        center: { lat: this.centerValue.latitude, lng: this.centerValue.longitude },
        zoom: 9,
        panControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        overviewMapControl: false
      }
    );

    const polygon = new window.google.maps.Polygon({
      paths: this.polygonValue,
      strokeColor: "blue",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "lightblue",
      fillOpacity: 0.25,
      clickable: false
    });

    polygon.setMap(googleMap);

    this.customersValue.forEach(customer => {
      const point = new window.google.maps.LatLng(customer.latitude, customer.longitude);
      const marker = new window.google.maps.Marker({
        map: googleMap,
        position: point,
        animation: window.google.maps.Animation.DROP,
        icon: {
          url: "https://maps.google.com/mapfiles/ms/icons/blue-pushpin.png"
        }
      });

      const infoWindow = new window.google.maps.InfoWindow({
        content: customer.info
      });

      marker.addListener("click", () => {
        infoWindow.open({
          anchor: marker,
          map: googleMap
        });
      });
    });
  }
}
