import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["select"];

  add(event) {
    const current = $(this.selectTarget).val();

    const userId = event.params.userid;
    let newValue = null;
    if (current) {
      if (typeof current == "string") {
        newValue = [current, userId];
      } else {
        // Making the assumption this is an array
        newValue = current + userId;
      }
    } else {
      newValue = userId;
    }

    $(this.selectTarget).select2("val", newValue);

    if (!window.onbeforeunload) {
      window.onbeforeunload = function() {
        return true;
      }
    }

    event.target.closest("tr").classList.add("invisible");
  }

  clearBeforeUnload() {
    window.onbeforeunload = null;
  }
}
