// Controls the order site form in the admin console

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["pickupProp", "deliveryProp"];
  static values = { siteType: String, pickupSiteTypes: Array, deliverySiteTypes: Array };

  connect() {
    this.siteTypeChanged({ target: { value: this.siteTypeValue } });
  }

  siteTypeChanged(event) {
    if (this.pickupSiteTypesValue.includes(event.target.value)) {
      this.hide(this.deliveryPropTargets);
      this.show(this.pickupPropTargets);
    } else if (this.deliverySiteTypesValue.includes(event.target.value)) {
      this.hide(this.pickupPropTargets);
      this.show(this.deliveryPropTargets);
    } else {
      this.hide(this.pickupPropTargets);
      this.hide(this.deliveryPropTargets);
    }
  }

  // private

  hide(targets) {
    targets.forEach(target => target.classList.add("hidden"));
  }

  show(targets) {
    targets.forEach(target => target.classList.remove("hidden"));
  }
}
