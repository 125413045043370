import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ["infoWindowTemplate", "map", "card"];
  static values = {
    userLoggedIn: Boolean,
    currentSiteId: Number,
    sites: Array
  }

  connect() {
    this.waitForGoogleMapsToLoad();
  }

  waitForGoogleMapsToLoad() {
    const self = this;
    setTimeout(() => {
      if (window.googleMapsLoaded) {
        self.init();
      } else {
        self.waitForGoogleMapsToLoad();
      }
    }, 100);
  }

  init() {
    const self = this;
    self.googleMap = new window.google.maps.Map(self.mapTarget, {controlSize: 20});

    self.sites = [];
    self.sitesValue.forEach(site => {
      let dotImage = site.status == 'coming_soon' ? 'yellow-dot.png' : 'red-dot.png';
      if (self.currentSiteIdValue == site.id) {
        dotImage = 'green-dot.png';
      }

      let position = this.resolveConflicts(self.sites, new google.maps.LatLng(site.lat, site.lng));

      self.sites.push({
        site: site,
        marker: new window.google.maps.Marker({
          map: self.googleMap,
          position: position,
          icon: {
            url: `https://maps.google.com/mapfiles/ms/micons/${dotImage}`
          }
        })
      });
    });

    const infoWindow = new window.google.maps.InfoWindow();
    self.sites.forEach(site => {
      const content = self.createInfoWindowContent(site.site);
      site.marker.addListener("click", () => {
        infoWindow.setContent(content);
        infoWindow.open(self.googleMap, site.marker);
      });

      if (site.site.id == self.currentSiteIdValue) {
        infoWindow.setContent(content);
        infoWindow.open(self.googleMap, site.marker);
      }
    });

    this.selectState({params: {state: "All"}});
  }

  selectState(event) {
    const bounds = new window.google.maps.LatLngBounds();
    this.sites.forEach(site => {
      if (site.site.state == event.params.state || event.params.state == "All") {
        bounds.extend(site.marker.getPosition());
      }
    });

    this.googleMap.fitBounds(bounds);

    this.cardTargets.forEach(card => {
      if (card.dataset.state == event.params.state || event.params.state == "All") {
        card.classList.remove("d-none");
        card.classList.add("d-block");
      } else {
        card.classList.remove("d-block");
        card.classList.add("d-none");
      }
    });
  }

  resolveConflicts(sites, position) {
    let conflicts = sites.filter(otherSite => otherSite.marker.getPosition().equals(position));
    if (conflicts.length > 0) {
      return this.resolveConflicts(new google.maps.LatLng(position.lat + 0.0001, position.lng + 0.0001));
    } else {
      return position;
    }
  }

  createInfoWindowContent(site) {
    const infoWindow = this.infoWindowTemplateTarget.content.firstElementChild.cloneNode(true);

    if (site.status == "coming_soon") {
      infoWindow.querySelector("[data-template-element='openForOrdersDate']").append(site.openForOrdersDate);
      infoWindow.querySelector("[data-template-element='launchDate']").append(site.launchDate);
      infoWindow.querySelector("[data-template-element='comingSoon']").classList.remove("d-none");
    }

    if (site.status == "full") {
      infoWindow.querySelector("[data-template-element='full']").classList.remove("d-none");
    }

    infoWindow.querySelector("[data-template-element='name']").append(site.name);
    infoWindow.querySelector("[data-template-element='address']").append(site.address);
    infoWindow.querySelector("[data-template-element='info']").append(site.info);

    if (site.exception) {
      let element = infoWindow.querySelector("[data-template-element='exception']");
      element.append(site.exception);
      element.classList.remove("d-none");
    }

    if (this.userLoggedInValue) {
      if (site.status == "coming_soon") {
        infoWindow.querySelector("[data-template-element='selectSite']").classList.add("d-none");
      }

      if (site.id == this.currentSiteIdValue) {
        infoWindow.querySelector("[data-template-element='selectSite']").classList.add("d-none");
      } else {
        infoWindow.querySelector("[data-template-element='selectSiteId']").value = site.id;
        infoWindow.querySelector("[data-template-element='currentSite']").classList.add("d-none");
      }
    }

    return infoWindow;
  }
}
