import { Controller } from "stimulus";

export default class extends Controller {
  show() {
    $('#progress').addClass('d-flex d-block');
  }

  hide() {
    $('#progress').removeClass('d-flex d-block');
  }
}
