// Controls the site form in the admin console

import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["status", "openForOrdersDate", "siteType", "pickupProp", "deliveryAreaProp", "deliveryProp"];
  static values = {
    pickupSiteTypes: Array,
    deliveryAreaSiteTypes: Array,
    deliverySiteTypes: Array
  };

  connect() {
    this.siteTypeChanged({target: this.siteTypeTarget});
    this.statusChanged({target: this.statusTarget});
  }

  siteTypeChanged(event) {
    if (this.pickupSiteTypesValue.includes(event.target.value)) {
      this.hide(this.deliveryAreaPropTargets);
      this.hide(this.deliveryPropTargets);
      this.show(this.pickupPropTargets);
    } else if (this.deliveryAreaSiteTypesValue.includes(event.target.value)) {
      this.hide(this.deliveryPropTargets);
      this.hide(this.pickupPropTargets);
      this.show(this.deliveryAreaPropTargets);
    } else if (this.deliverySiteTypesValue.includes(event.target.value)) {
      this.hide(this.pickupPropTargets);
      this.hide(this.deliveryAreaPropTargets);
      this.show(this.deliveryPropTargets);
    } else {
      this.hide(this.pickupPropTargets);
      this.hide(this.deliveryAreaPropTargets);
      this.hide(this.deliveryPropTargets);
    }
  }

  statusChanged(event) {
    if (event.target.value == "coming_soon") {
      this.show([this.openForOrdersDateTarget]);
    } else {
      this.hide([this.openForOrdersDateTarget]);
    }
  }

  // private

  hide(targets) {
    targets.forEach(target => target.classList.add("hidden"));
  }

  show(targets) {
    targets.forEach(target => target.classList.remove("hidden"));
  }
}
