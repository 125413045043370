import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["map"];
  static values = {
    center: Object,
    zoom: Number,
    polygons: Array,
  };

  connect() {
    const googleMap = new window.google.maps.Map(
      this.mapTarget,
      {
        center: { lat: parseFloat(this.centerValue.lat), lng: parseFloat(this.centerValue.lng) },
        zoom: this.zoomValue,
        panControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        overviewMapControl: false
      }
    );

    const infoWindow = new window.google.maps.InfoWindow();
    this.polygonsValue.forEach(data => {
      const polygon = new window.google.maps.Polygon({
        paths: data.polygon,
        strokeColor: data.color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: data.color,
        fillOpacity: 0.25
      });

      polygon.setMap(googleMap);

      polygon.addListener("click", event => {
        infoWindow.close();
        infoWindow.setContent(
          document.getElementById(`info-window-${data.site_id}`).content.cloneNode(true)
        );
        infoWindow.setPosition(event.latLng);
        infoWindow.open({ map: googleMap });
      });
    });
  }
}
