import {Controller} from 'stimulus';

export default class extends Controller {
  static values = {url: String};

  connect() {
    const self = this;
    fetch(self.urlValue, {headers: {"Content-Type": "text/html"}})
      .then(response => {
        response.text().then(card => {
          $(self.element).html(card);
        });
      });
  }
}
