// Controls spree/shopping_list/_order_summary

import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { url: String };

  connect() {
    this.abortController = new AbortController();

    this.listenForEvents(
      [
        'shopping-list-product-added',
        'shopping-list-product-removed',
        'product-added-to-shopping-list',
        'product-removed-from-shopping-list'
      ],
      () => this.replaceOrderSummary(),
      this.abortController.signal
    );
  }

  disconnect() {
    this.abortController.abort();
  }

  listenForEvents(eventIdList, callback, signal) {
    eventIdList.forEach(eventId => {
      document.addEventListener(eventId, callback, { signal });
    })
  }

  replaceOrderSummary() {
    this.getOrderSummaryAjaxCall = $.ajax({
      type: 'GET',
      url: this.urlValue,
      beforeSend: () => {
        if (this.getOrderSummaryAjaxCall) {
          // Cancel the previous request if it exists
          this.getOrderSummaryAjaxCall.abort();
        }
      }
    }).done(data => {
      $(this.element).replaceWith(data);
    });
  }
}
