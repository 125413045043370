import { Controller } from 'stimulus';

// Listen for events related to adding and removing products from a shopping
// list. Query the server for the latest status of the shopping list. All
// target elements will be hidden if the status is valid or shown if the
// status is invalid. Use the value "enabled" to prevent this query.
export default class extends Controller {
  static values = {
    enabled: Boolean,
    url: String
  };

  static targets = ["element"]

  connect() {
    if (this.enabledValue == false) {
      return;
    }

    this.abortController = new AbortController();

    this.listenForEvents(
      [
        'product-added-to-shopping-list',
        'product-removed-from-shopping-list',
        'shopping-list-product-added',
        'shopping-list-product-removed'
      ],
      () => this.checkShoppingListIsValid(),
      this.abortController.signal
    );
  }

  listenForEvents(eventIdList, callback, signal) {
    eventIdList.forEach(eventId => {
      document.addEventListener(eventId, callback, { signal })
    })
  }

  async checkShoppingListIsValid() {
    const response = await fetch(this.urlValue);
    const valid = await response.json();

    if (this.previousResponse == valid) {
      return;
    }

    this.previousResponse = valid;
    if (valid) {
      this.hideElements();
    } else {
      this.showElements();
    }
  }

  hideElements() {
    this.elementTargets.forEach(element => {
      element.classList.add("d-none");
    });
  }

  showElements() {
    this.elementTargets.forEach(element => {
      element.classList.remove("d-none");
    });
  }

  disconnect() {
    this.abortController.abort();
  }
}
