import {Controller} from "stimulus";

export default class extends Controller {
  static targets = [
    "map",
    "legendTemplate",
    "infoWindowTemplate",
    "infoWindowSiteSelectButton",
    "infoWindowComingSoonBanner",
    "polygonInfoWindowTemplate",
    "deliveryAddressModal",
    "deliveryAddressFormAddress1",
    "deliveryAddressFormAddress2",
    "deliveryAddressFormCity",
    "deliveryAddressFormState",
    "deliveryAddressFormZipcode",
    "deliveryAddressFormCountry",
    "updateUserForm",
    "updateUserFormSite",
    "updateUserFormAddress1",
    "updateUserFormAddress2",
    "updateUserFormCity",
    "updateUserFormCountry",
    "updateUserFormState",
    "updateUserFormZipcode"
  ];

  static values = {
    loggedIn: Boolean,
    openAddressModalOnLoad: Boolean,
    currentSiteId: Number,
    deliveryAreas: Array,
    selectedState: String
  };

  connect() {
    this.waitForGoogleMapsToLoad();
  }

  waitForGoogleMapsToLoad() {
    const self = this;
    setTimeout(() => {
      if (window.googleMapsLoaded) {
        self.init();
      } else {
        self.waitForGoogleMapsToLoad();
      }
    }, 100);
  }

  init() {
    const self = this;
    self.googleMap = new window.google.maps.Map(self.mapTarget, {controlSize: 20});

    self.areas = self.deliveryAreasValue.map(area => {
      return {
        id: area.id,
        label: area.map_polygon_label,
        color: area.map_polygon_color,
        state: area.state,
        status: area.status,
        pickupDay: area.pickup_day,
        openForOrdersDate: area.open_for_orders_date,
        launchDate: area.launch_date,
        polygon: new window.google.maps.Polygon({
          paths: area.map_polygon,
          strokeColor: area.map_polygon_color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: area.map_polygon_color,
          fillOpacity: 0.25,
          clickable: true
        })
      }
    });

    const polygonInfoWindow = new window.google.maps.InfoWindow();
    self.areas.forEach(area => {
      area.polygon.setMap(self.googleMap);

      const content = self.createPolygonInfoWindowContent(area);
      area.polygon.addListener("click", event => {
        polygonInfoWindow.setContent(content);
        polygonInfoWindow.setPosition(event.latLng);
        polygonInfoWindow.open(self.googleMap);
      });
    });

    const legend = self.legendTemplateTarget.content.firstElementChild.cloneNode(true);
    self.googleMap.controls[window.google.maps.ControlPosition.LEFT_BOTTOM].push(legend);

    self.selectState({params: {state: "All"}});

    if (self.loggedInValue) {
      self.checkDeliveryAddress(null, self.openAddressModalOnLoadValue);
    }
  }

  createPolygonInfoWindowContent(area) {
    const infoWindow = this.polygonInfoWindowTemplateTarget.content.firstElementChild.cloneNode(true);

    if (area.status == "coming_soon") {
      infoWindow.querySelector("[data-template-element='openForOrders']").append(area.openForOrdersDate);
      infoWindow.querySelector("[data-template-element='launch']").append(area.launchDate);
      infoWindow.querySelector("[data-template-element='comingSoon']").classList.remove("d-none");
    } else {
      const element = infoWindow.querySelector("[data-template-element='open']");
      element.append(`Available for delivery on ${area.pickupDay}`);
      element.classList.remove("d-none");
    }

    return infoWindow;
  }

  selectState(event) {
    const self = this;

    const currentStateTarget = self.currentStateTarget;
    const desiredStateTarget = event.params.state;
    if (currentStateTarget == desiredStateTarget) {
      return;
    }

    if (desiredStateTarget == "All") {
      self.fitMapToAll();
      self.currentStateTarget = "All";
    } else if (currentStateTarget == "All") {
      self.fitMapToState(desiredStateTarget);
      self.currentStateTarget = desiredStateTarget;
    } else {
      // There's an issue with Google Maps where the layout breaks if calling fitBounds
      // with bounds that are very distant. To work around this, we zoom all the way out
      // and then back in.
      const listener = window.google.maps.event.addListener(self.googleMap, "idle", () => {
        self.fitMapToState(desiredStateTarget);
        window.google.maps.event.removeListener(listener);
      });

      self.fitMapToAll();
      self.currentStateTarget = desiredStateTarget;
    }
  }

  fitMapToAll() {
    const bounds = new window.google.maps.LatLngBounds();
    this.areas.forEach(area => {
      // getPaths() returns an array of arrays of points
      area.polygon.getPaths().forEach(arr => {
        arr.forEach(point => {
          bounds.extend(point);
        });
      });
    });

    this.googleMap.fitBounds(bounds);
  }

  fitMapToState(state) {
    const bounds = new window.google.maps.LatLngBounds();
    this.areas.forEach(area => {
      if (area.state == state) {
        // getPaths() returns an array of arrays of points
        area.polygon.getPaths().forEach(arr => {
          arr.forEach(point => {
            bounds.extend(point);
          });
        });
      }
    });

    this.googleMap.fitBounds(bounds);
  }

  checkDeliveryAddress(event, showDeliveryAddress) {
    let addressUpdated = false;

    if (event) {
      event.preventDefault();
      const form = event.target.form;
      const valid = form.reportValidity();
      addressUpdated = true;
      if (!valid) {
        return;
      }
    }

    const address = this.deliveryAddressFormAddress1Target.value;
    const address2 = this.deliveryAddressFormAddress2Target.value;
    const city = this.deliveryAddressFormCityTarget.value;
    const state = this.deliveryAddressFormStateTarget.selectedOptions[0].text;
    const zipcode = this.deliveryAddressFormZipcodeTarget.value;

    const self = this;
    Spree.ajax({
      url: Spree.pathFor('/googleapis.json'),
      data: {address: {address, city, state, zipcode}},
      success: function (data) {
        self.hideDeliveryAddressModal();

        if (data.error) {
          self.showDeliveryAddressModal();
          return;
        }

        self.setPin(data.latitude, data.longitude, address, address2, city, state, zipcode, addressUpdated);

        if (showDeliveryAddress) {
          self.showDeliveryAddressModal();
        }
      }
    });
  }

  setPin(lat, lng, address, address2, city, state, zipcode, addressUpdated) {
    const position = new window.google.maps.LatLng(lat, lng);

    const eligibleSites = this.areas.filter(area => window.google.maps.geometry.poly.containsLocation(position, area.polygon));

    const infoWindow = new window.google.maps.InfoWindow();
    const content = this.createInfoWindowContent(address, address2, city, state, zipcode, addressUpdated, eligibleSites)
    infoWindow.setContent(content);

    const marker = new window.google.maps.Marker({
      map: this.googleMap,
      position: position,
      animation: window.google.maps.Animation.DROP,
      icon: {
        url: `https://maps.google.com/mapfiles/ms/icons/${eligibleSites.length > 0 ? "grn" : "red"}-pushpin.png`
      }
    });

    const self = this;
    marker.addListener("click", () => {
      infoWindow.open(self.googleMap, marker);
    });

    infoWindow.open(this.googleMap, marker);

    if (this.marker) {
      this.marker.setMap(null);
    }

    this.marker = marker;
    this.googleMap.panTo(position);
  }

  /* eligibleSites: Array<{id:, label:}> */
  createInfoWindowContent(address, address2, city, state, zipcode, addressUpdated, eligibleSites) {
    const self = this;
    const content = this.infoWindowTemplateTarget.content.firstElementChild.cloneNode(true);

    if (eligibleSites.length > 0) {
      const currentSite = content.querySelector("[data-template-element='currentSite']");
      const selectSite = content.querySelector("[data-template-element='selectSite']");

      eligibleSites.forEach(site => {
        if (addressUpdated && site.id == this.currentSiteIdValue) {
          const button = this.infoWindowSiteSelectButtonTarget.content.firstElementChild.cloneNode(true);
          button.append(`Keep ${site.label} as my Delivery Day`);
          button.style.backgroundColor = site.color;
          button.addEventListener("click", () => {
            self.chooseSite(site.id);
          });
          selectSite.append(button);
        } else if (site.id == this.currentSiteIdValue) {
          currentSite.innerText = `You're signed up for ${site.label}`;
          currentSite.classList.remove("d-none");
        } else if (site.status == "coming_soon") {
          const banner = this.infoWindowComingSoonBannerTarget.content.firstElementChild.cloneNode(true);
          banner.style.backgroundColor = site.color + "50";
          banner.querySelector("[data-template-element='label']").append(site.label);
          banner.querySelector("[data-template-element='launch']").append(site.launchDate);
          banner.querySelector("[data-template-element='openForOrders']").append(site.openForOrdersDate);
          content.querySelector("[data-template-element='comingSoon']").append(banner);
        } else {
          const button = this.infoWindowSiteSelectButtonTarget.content.firstElementChild.cloneNode(true);
          button.append(`Make ${site.label} my Delivery Day`);
          button.style.backgroundColor = site.color;
          button.addEventListener("click", () => {
            self.chooseSite(site.id);
          });
          selectSite.append(button);
        }
      });
    } else {
      content.querySelector("[data-template-element='notEligible']").classList.remove("d-none");
    }

    content.querySelector("[data-template-element='address']").append(
      `${address} ${address2}`,
      document.createElement("br"),
      `${city}, ${state} ${zipcode}`
    );

    content.querySelector("[data-template-element='changeAddress']").addEventListener("click", () => {
      self.showDeliveryAddressModal();
    });

    return content;
  }

  showDeliveryAddressModal() {
    $(this.deliveryAddressModalTarget).modal("show");
  }

  hideDeliveryAddressModal() {
    $(this.deliveryAddressModalTarget).modal("hide");
  }

  chooseSite(siteId) {
    this.updateUserFormSiteTarget.value = siteId;
    this.updateUserFormAddress1Target.value = this.deliveryAddressFormAddress1Target.value;
    this.updateUserFormAddress2Target.value = this.deliveryAddressFormAddress2Target.value;
    this.updateUserFormCityTarget.value = this.deliveryAddressFormCityTarget.value;
    this.updateUserFormCountryTarget.value = this.deliveryAddressFormCountryTarget.value;
    this.updateUserFormStateTarget.value = this.deliveryAddressFormStateTarget.selectedOptions[0].value;
    this.updateUserFormZipcodeTarget.value = this.deliveryAddressFormZipcodeTarget.value;
    this.updateUserFormTarget.submit();
  }
}
