// Controls a product when in the detail view
// See app/views/spree/products/show.html.erb

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['disableWhileLoading', 'tooltip'];
  static values = { isPreOrder: Boolean };

  connect() {
    this.enableTooltips();
  }

  addToFavorites(event) {
    this.handle(event);
  }

  removeFromFavorites(event) {
    this.handle(event);
  }

  incrementInShoppingList(event) {
    const self = this;
    self.handle(event, function() {
      const productAddedEvent = new CustomEvent(
        'product-added-to-shopping-list',
        { detail: { isPreOrder: self.isPreOrderValue } }
      );

      document.dispatchEvent(productAddedEvent);
    });
  }

  decrementInShoppingList(event) {
    const self = this;
    self.handle(event, function() {
      const productAddedEvent = new CustomEvent(
        'product-removed-from-shopping-list',
        { detail: { isPreOrder: self.isPreOrderValue } }
      );

      document.dispatchEvent(productAddedEvent);
    });
  }

  enableTooltips() {
    $("[data-toggle='tooltip']").tooltip('enable');
  }

  hideTooltips() {
    $("[data-toggle='tooltip']").tooltip('hide');
  }

  handle(event, onSuccess) {
    event.preventDefault();
    const self = this;
    self.startLoading();

    self.hideTooltips();

    const form = $(event.target).closest('form');

    $.post(
      form[0].action,
      form.serialize()
    ).done(function(data, _status, xhr) {
      // TODO: If auth or csrf fails Rails is returning a Turbolinks script. Since we
      // don't actually have Turbolinks, it fails. Instead, redirect if JS is returned.
      if (xhr.getResponseHeader('content-type').startsWith('text/javascript')) {
        window.location = Spree.pathFor('login');
        return;
      }

      $(self.element).replaceWith(data);
      $('input[type=radio].star').rating();
      self.endLoading();

      onSuccess && onSuccess();
    });
  }

  startLoading() {
    this.element.classList.add('product-loading');
    this.disableWhileLoadingTargets.forEach(element => element.disabled = true);
  }

  endLoading() {
    this.element.classList.remove('product-loading');
    this.disableWhileLoadingTargets.forEach(element => element.disabled = false);
  }
}
