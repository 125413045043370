// Controls the product details form in the admin console

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'addonFlag',
    'addonCategoryInput',

    'limitedQuantityFlag',
    'limitedQuantityRemoveFieldContainer',
    'initialQuantityFieldContainer',
    'quantityFieldContainer',

    'preOrderFlag',
    'preOrderDeadlineFieldContainer',
    'preOrderReadyForOrderFieldContainer',

    'weightFieldContainer',
    'weightInput',

    'variableWeightFlag',
    'priceInput',
    'pricePerPoundFieldContainer',
    'pricePerPoundInput',

    'variableWeightPurchasingFlag',
    'costInput',
    'costPerPoundFieldContainer',
    'costPerPoundInput'
  ];

  toggleAddon() {
    if (this.addonFlagTarget.checked) {
      this.enable(this.addonCategoryInputTarget);
    } else {
      this.disable(this.addonCategoryInputTarget);
    }
  }

  toggleLimitedQuantity() {
    if (this.limitedQuantityFlagTarget.checked) {
      this.show(this.limitedQuantityRemoveFieldContainerTarget);
      this.show(this.initialQuantityFieldContainerTarget);
      this.show(this.quantityFieldContainerTarget);
    } else {
      this.hide(this.limitedQuantityRemoveFieldContainerTarget);
      this.hide(this.initialQuantityFieldContainerTarget);
      this.hide(this.quantityFieldContainerTarget);
    }
  }

  togglePreOrder() {
    if (this.preOrderFlagTarget.checked) {
      this.show(this.preOrderDeadlineFieldContainerTarget);
      this.show(this.preOrderReadyForOrderFieldContainerTarget);
    } else {
      this.hide(this.preOrderDeadlineFieldContainerTarget);
      this.hide(this.preOrderReadyForOrderFieldContainerTarget);
    }
  }

  toggleVariableWeight(event) {
    if (this.variableWeightFlagTarget.checked) {
      this.show(this.pricePerPoundFieldContainerTarget);
      this.disable(this.priceInputTarget);
    } else {
      this.hide(this.pricePerPoundFieldContainerTarget);
      this.enable(this.priceInputTarget);
    }

    this.toggleWeightField();
  }

  toggleVariableWeightPurchasing() {
    if (this.variableWeightPurchasingFlagTarget.checked) {
      this.show(this.costPerPoundFieldContainerTarget);
      this.disable(this.costInputTarget);
    } else {
      this.hide(this.costPerPoundFieldContainerTarget);
      this.enable(this.costInputTarget);
    }

    this.toggleWeightField();
  }

  toggleWeightField() {
    if (this.variableWeightFlagTarget.checked || this.variableWeightPurchasingFlagTarget.checked) {
      this.show(this.weightFieldContainerTarget);
    } else {
      this.hide(this.weightFieldContainerTarget);
    }
  }

  recalculatePrice() {
    if (this.variableWeightFlagTarget.checked) {
      let price = this.weightInputTarget.value * this.pricePerPoundInputTarget.value;

      // round to nearest $0.05
      price = Math.round(price * 20) / 20;

      this.priceInputTarget.value = price.toFixed(2);
    }
  }

  recalculateCost() {
    if (this.variableWeightPurchasingFlagTarget.checked) {
      const cost = this.weightInputTarget.value * this.costPerPoundInputTarget.value;
      this.costInputTarget.value = cost.toFixed(2);
    }
  }

  // private

  show(target) {
    target.classList.remove('hidden');
    target.classList.add('highlight');
    setTimeout(() => target.classList.remove('highlight'));
  }

  hide(target) {
    target.classList.add('hidden');
  }

  disable(target) {
    target.disabled = true;
  }

  enable(target) {
    target.disabled = false;
  }
}
