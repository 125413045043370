import {Controller} from "stimulus";

export default class extends Controller {
  static values = {
    timeout: Number // in milliseconds
  }

  connect() {
    setTimeout(() => window.location.reload(), this.timeoutValue);
  }
}
