// Controls a product when shopping
// See app/views/spree/shared/_product.html.erb

import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = [
    'disableWhileLoading',
    'dayRestriction',
    'addFavorite',
    'removeFavorite',
    'addProduct',
    'soldOutTooltip',
    'notForSaleTooltip',
    'removeProduct',
    'quantityBlock',
    'quantity',
  ];

  static values = {
    isUserLoggedIn: Boolean,
    isUserActive: Boolean,
    isPreOrder: Boolean,
    isFavorite: Boolean,
    quantity: Number,
    isSoldOut: Boolean,
    modalElementId: String
  };

  connect() {
    this.enableTooltips();
  }

  enableTooltips() {

    if (this.hasAddFavoriteTarget) {
      $(this.addFavoriteTarget).tooltip('enable');
    }

    if (this.hasRemoveFavoriteTarget) {
      $(this.removeFavoriteTarget).tooltip('enable');
    }

    if (this.hasDayRestrictionTarget) {
      $(this.dayRestrictionTarget).tooltip('enable');
    }

    if (this.hasSoldOutTooltipTarget) {
      $(this.soldOutTooltipTarget).tooltip('enable');
    }

    if (this.hasNotForSaleTooltipTarget) {
      $(this.notForSaleTooltipTarget).tooltip('enable');
    }
  }

  hideTooltips() {
    if (this.hasAddFavoriteTarget) {
      $(this.addFavoriteTarget).tooltip('hide');
    }

    if (this.hasRemoveFavoriteTarget) {
      $(this.removeFavoriteTarget).tooltip('hide');
    }

    if (this.hasDayRestrictionTarget) {
      $(this.dayRestrictionTarget).tooltip('hide');
    }

    if (this.hasSoldOutTooltipTarget) {
      $(this.soldOutTooltipTarget).tooltip('hide');
    }

    if (this.hasNotForSaleTooltipTarget) {
      $(this.notForSaleTooltipTarget).tooltip('hide');
    }
  }

  favorite(event) {
    event.preventDefault();
    this.hideTooltips();
    this.handle(event);
  }

  removeFavorite(event) {
    event.preventDefault();
    this.hideTooltips();
    this.handle(event);
  }

  add(event) {
    event.preventDefault();
    const self = this;

    if (!self.isUserLoggedInValue || !self.isUserActiveValue) {
      $(self.modalElementIdValue).modal('show');
      return;
    }

    self.handle(event, function () {
      const productAddedEvent = new CustomEvent(
        'product-added-to-shopping-list',
        {detail: {isPreOrder: self.isPreOrderValue}}
      );

      document.dispatchEvent(productAddedEvent);
    });
  }

  remove(event) {
    event.preventDefault();
    const self = this;
    self.handle(event, function () {
      const productRemovedEvent = new CustomEvent(
        'product-removed-from-shopping-list',
        {detail: {isPreOrder: self.isPreOrderValue}}
      );

      document.dispatchEvent(productRemovedEvent);
    });
  }

  handle(event, onSuccess) {
    const self = this;
    self.startLoading();

    const form = $(event.target).closest('form');

    $.post(
      form[0].action,
      form.serialize()
    ).done(function (data, _status, xhr) {
      // TODO: If auth or csrf fails Rails is returning a Turbolinks script. Since we
      // don't actually have Turbolinks, it fails. Instead, redirect if JS is returned.
      if (xhr.getResponseHeader('content-type').startsWith('text/javascript')) {
        window.location = Spree.pathFor('login');
        return;
      }

      $(self.element).replaceWith(data);
      self.endLoading();

      onSuccess && onSuccess();
    });
  }

  startLoading() {
    this.element.classList.add('product-loading');
    this.disableWhileLoadingTargets.forEach(element => element.disabled = true);
  }

  endLoading() {
    this.element.classList.remove('product-loading');
    this.disableWhileLoadingTargets.forEach(element => element.disabled = false);
  }
}
