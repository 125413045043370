// Asynchronously loads modal content after the modal is shown.
// Uses a template in the DOM as the default display until the
// content arrives. The content is swapped out for the template
// once the modal is hidden from view.

import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { loadingTemplateId: String, url: String, showImmediately: Boolean };

  connect() {
    const self = this;

    $(self.element).html(
      document.getElementById(self.loadingTemplateIdValue).content.cloneNode(true)
    );

    $(self.element).on('show.bs.modal', () => {
      $.get(self.urlValue)
        .done(data => {
          $(self.element).html(data);
        });
    });

    $(self.element).on('hidden.bs.modal', () => {
      $(self.element).html(
        document.getElementById(self.loadingTemplateIdValue).content.cloneNode(true)
      );
    });

    if (self.showImmediatelyValue) {
      $(self.element).modal();
    }
  }
}
