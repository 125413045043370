// <div>
//   <%=
//    select_tag :url_selector,
//      options_from_collection_for_select(
//        Spree::HubSnapshot.order(:id),
//        ->(snapshot) { report_admin_finances_snapshot_path(snapshot.id) },
//        :snapshot_timestamp
//      )
//   %>
//   <button
//    data-controller="open-tab-from-select"
//    data-open-tab-from-select-select-value="#url_selector"
//    data-open-tab-from-select-extension-value=".xlsx
//    data-action="open-tab-from-select#open"
//   >
//    Open
//   </div>
// </div>

import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    select: String, // CSS selector
    extension: String
  };

  open() {
    let url = $(this.selectValue)[0].value;
    if (this.hasExtensionValue) {
      url += this.extensionValue;
    }

    window.open(url, '_blank');
  }
}
