import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const url = new URL(this.element.href)
    if (url.pathname == window.location.pathname) {
      this.element.classList.add("active");
    }
  }
}
