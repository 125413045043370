import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { url: String, replacing: Boolean };

  initialize() {
    const self = this;
    const observer = new IntersectionObserver((e, o) => {
      if (e[0].isIntersecting) {
        o.unobserve(self.element);
        self.replace();
      }
    },{
      threshold: [0.1]
    });

    observer.observe(self.element);
  }

  replace() {
    const self = this;
    $.get(self.urlValue)
      .done(data => {
        self.element.outerHTML = data;
      });
  }
}
