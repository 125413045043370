// Used by spree/shopping_lists/edit

import { Controller } from "stimulus"

export default class extends Controller {
  static values = { formUrl: String };
  static targets = [ 'productList' ];

  connect() {
    this.abortController = new AbortController();

    // Initialize the list of products
    this.refreshShoppingList();

    // Product added from Favorites carousel
    document.addEventListener(
      'product-added-to-shopping-list',
      () => this.refreshShoppingList(),
      { signal: this.abortController.signal }
    );

    // Product removed from Favorites carousel
    document.addEventListener(
      'product-removed-from-shopping-list',
      () => this.refreshShoppingList(),
      { signal: this.abortController.signal }
    );
  }

  refreshShoppingList() {
    Spree.ajax({
      url: this.formUrlValue,
      datatype: 'html',
      success: data => {
        $(this.productListTarget).html(data);
      }
    });
  }

  disconnect() {
    this.abortController.abort();
  }
}
