// Controls a product when viewing the shopping list
// See app/views/spree/shopping_lists/_shopping_list_product

import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ['disableWhileLoading'];
  static values = {isPreOrder: Boolean, quantity: Number};

  favorite(event) {
    this.handle(event);
  }

  removeFavorite(event) {
    this.handle(event);
  }

  add(event) {
    const self = this;
    self.handle(event, function () {
      const productAddedEvent = new CustomEvent(
        'shopping-list-product-added',
        {detail: {isPreOrder: self.isPreOrderValue}}
      );

      document.dispatchEvent(productAddedEvent);
    });
  }

  remove(event) {
    const self = this;
    self.handle(event, function () {
      const productAddedEvent = new CustomEvent(
        'shopping-list-product-removed',
        {detail: {isPreOrder: self.isPreOrderValue}}
      );

      document.dispatchEvent(productAddedEvent);
    });
  }

  handle(event, onSuccess) {
    event.preventDefault();
    const self = this;
    self.startLoading();

    const form = $(event.target).closest('form');

    $.post(
      form[0].action,
      form.serialize()
    ).done(function (data, _status, xhr) {
      // TODO: If auth or csrf fails Rails is returning a Turbolinks script. Since we
      // don't actually have Turbolinks, it fails. Instead, redirect if JS is returned.
      if (xhr.getResponseHeader('content-type').startsWith('text/javascript')) {
        window.location = Spree.pathFor('login');
        return;
      }

      if (!data) {
        // Shopper removed product from shopping list
        $(self.element).animate(
          {opacity: 0},
          {
            complete: () => {
              self.element.remove();
            }
          }
        );
      } else {
        $(self.element).replaceWith(data);
        self.endLoading();
      }

      onSuccess && onSuccess();
    });
  }

  startLoading() {
    this.element.classList.add('product-loading');
    this.disableWhileLoadingTargets.forEach(element => element.disabled = true);
  }

  endLoading() {
    this.element.classList.remove('product-loading');
    this.disableWhileLoadingTargets.forEach(element => element.disabled = false);
  }
}
