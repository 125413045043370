// Requires donation frequency to be set if an amount is selected

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [ 'amount', 'frequencyOption' ];

  connect() {
    const self = this;

    $(self.amountTarget).on('change', () => {
      const required = !!self.amountTarget.value;

      self.frequencyOptionTargets.forEach(option => {
        option.required = required;
      });
    });
  }
}
