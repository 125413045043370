// See app/views/spree/admin/pick_zones/show.html.erb

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["moveButton", "modalProductIds", "checkBox"];

  toggleButton() {
    if (this.checkedForMove().length > 0) {
      this.moveButtonTarget.disabled = false;
    } else {
      this.moveButtonTarget.disabled = true;
    }
  }

  fillModal() {
    this.modalProductIdsTarget.value = this.checkedForMove()
      .map(e => e.id)
      .join(",");
  }

  checkedForMove() {
    return this.checkBoxTargets.filter(target => target.checked);
  }
}

