// Applies to the jQuery UI Datepicker component to the element

import {Controller} from 'stimulus';

export default class extends Controller {
  static targets = ["field", "altField", "selectDateWarning"];
  static values = {
    availableDates: Array,
    maxDate: String
  };

  connect() {
    $(this.fieldTarget).datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      numberOfMonths: 1,
      dateFormat: "D, mm/dd/yy",
      altField: this.hasAltFieldTarget ? this.altFieldTarget : undefined,
      altFormat: "yy-mm-dd",
      beforeShowDay: this.selectableDays.bind(this),
      minDate: new Date(this.availableDatesValue[0]),
      maxDate: this.hasMaxDateValue ? new Date(this.maxDateValue) : new Date(this.availableDatesValue[this.availableDatesValue.length - 1])
    });

    if (this.hasAltFieldTarget && this.hasSelectDateWarningTarget) {
      // Datepicker defaults alt field upon instantiation. Clear it out to allow detection of no user selection.
      $(this.altFieldTarget).val(undefined);
      this.element.addEventListener("submit", this.validateDateSelected.bind(this));
    }
  }

  validateDateSelected(event) {
    if (this.altFieldTarget.value === "") {
      $(this.selectDateWarningTarget).show();
      event.preventDefault();
    }
  }

  selectableDays(date) {
    if (this.availableDatesValue.includes(date.toISOString().slice(0, 10))) {
      return [true, ""];
    } else {
      return [false, ""]
    }
  }
}
