import {Controller} from "stimulus";

export default class extends Controller {
  static values = {url: String, modalKey: String};
  static targets = ["modal"]

  connect() {
    const localStorageKey = "toast-last-seen";
    const self = this;

    if (!self.urlValue) {
      return;
    }

    const lastSeenValue = window.localStorage.getItem(localStorageKey);
    const url = new URL(self.urlValue);

    if (lastSeenValue) {
      const lastSeen = JSON.parse(lastSeenValue);
      if (lastSeen.id === self.modalKeyValue && Date.now() <= this.eightDaysAfter(lastSeen.date)) {
        return;
      }
    }

    $.get(url).done(data => {
      if (!data) {
        return;
      }

      $(self.element).append(data);

      setTimeout(() => {
        $(self.modalTarget).modal();
        window.localStorage.setItem(
          localStorageKey,
          JSON.stringify({id: self.modalTarget.id, date: Date.now()})
        );
      }, 1500);
    });
  }

  eightDaysAfter(date) {
    return new Date(date + 691200000);
  }
}
