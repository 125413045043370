import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["product", "price"];
  static values = {url: String, currentPrice: Number};

  connect() {
    $(this.productTarget).on("change", this.changed.bind(this));
    if (this.productTarget.value !== "") {
      this.priceTarget.classList.remove("invisible");
    }
  }

  changed(event) {
    if (!this.hasPriceTarget) { return; }

    $.get(Spree.routes.product_search, {
      ids: [event.target.value],
      token: Spree.api_key
    }, data => {
      const product = data.products[0];
      const label = this.priceTarget.querySelector("label");
      label.innerHTML = `Price for ${product.name}`;

      const price = this.priceTarget.querySelector("input");
      const substitutionPrice = product.variable_weight ? product.price_per_pound : product.price;
      if (this.currentPriceValue < substitutionPrice) {
        price.value = this.currentPriceValue;
      } else {
        price.value = substitutionPrice;
      }

      this.priceTarget.querySelector(".input-group-append").classList.remove("d-none");
      this.priceTarget.classList.remove("invisible");
    });
  }
}
