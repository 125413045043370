// <div>
//   <%=
//    select_tag :url_selector,
//      options_from_collection_for_select(
//        Spree::HubSnapshot.order(:id),
//        ->(snapshot) { report_admin_finances_snapshot_path(snapshot.id) },
//        :snapshot_timestamp
//      )
//   %>
//   <button
//    data-controller="load-section-from-select"
//    data-load-section-from-select-select-value="#url_selector"
//    data-load-section-from-select-target-value="#replace-me"
//    data-action="load-selection-from-select#load"
//   >
//    Load
//   </div>
// </div>
// <div id="replace-me"></div>

import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    select: String, // CSS selector
    target: String // CSS selector
  };

  load() {
    const self = this;
    const url = $(self.selectValue)[0].value;

    $.get(url).done(function(data) {
      $(self.targetValue).html(data);
    });
  }
}
