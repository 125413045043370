import { Controller } from "stimulus"

export default class extends Controller {
  static values = {path: String};

  initialize() {
    const self = this;

    Spree.ajax({
      url: this.pathValue,
      datatype: 'html',
      success: function(data) {
        $(self.element).html(data);
      }
    });
  }
}
