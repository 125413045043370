import {Controller} from "stimulus";

// This can be placed directly on a form element or above a set of forms, such as on table of inline, async forms.
export default class extends Controller {
  connect() {
    // TODO: We're doing nothing because it's only causing issues.

    // const self = this;
    // self.changed = false;
    // self.submitting = false;
    //
    // self.element.querySelectorAll("input, select, textarea")
    //   .forEach(element => {
    //     element.addEventListener("change", self.registerWarn.bind(self));
    //   });
    //
    // // If this controller is attached to a form, listen for its submit event.
    // // Otherwise, find any forms contained by this element and listen.
    // if (self.element.tagName == "FORM") {
    //   self.element.addEventListener("submit", self.registerSubmitting.bind(self));
    // } else {
    //   self.element.querySelectorAll("form")
    //     .forEach(element => {
    //       element.addEventListener("submit", self.registerSubmitting.bind(self));
    //     });
    // }
  }

  registerSubmitting() {
    this.submitting = true;
  }

  registerWarn() {
    const self = this;
    if (self.changed) {
      return;
    }

    self.changed = true;
    addEventListener("beforeunload", event => {
      if (self.submitting == true) {
        return;
      }

      return (event.returnValue = "");
    });
  }
}
