import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { defaultTab: String };

  connect() {
    const anchor = document.location.hash.slice(1).toLowerCase();
    const tabs = Array.from(this.element.querySelectorAll(".nav-link"));
    let shown = false;
    tabs.forEach(child => {
      if (child.id == anchor) {
        $(child).tab("show");
        shown = true;
      }
    });

    if (!shown) {
      $(tabs.filter(tab => tab.id == this.defaultTabValue)).tab("show");
    }
  }
}
