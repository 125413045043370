// Attach this controller and action to a select element where the value
// is a URL and the location will be set to the location when #go is called.
//
// <%=
//   collection_select :snapshots,
//    :id,
//    Spree::Hub.farmers_to_you.hub_snapshots.order(created_at: :desc),
//    ->(snapshot) { admin_finances_snapshot_url(id: snapshot.id) },
//    ->(snapshot) { snapshot.created_at.to_s(:admin_date) },
//    {
//      selected: admin_finances_snapshot_url(id: @snapshot_id),
//      required: true
//    },
//    class: "select2",
//    data: {
//      "controller": "spinner navigate-on-select",
//      "action": "change->spinner#show change->navigate-on-select#go"
//    }
// %>

import {Controller} from "stimulus";

export default class extends Controller {
  go() {
    window.location = this.element.value;
  }
}
