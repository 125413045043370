import { Controller } from 'stimulus';

// TODO: may be able to replace with navigate_on_select_controller
export default class extends Controller {
  static targets = ['datepicker'];

  connect() {
    const self = this;

    $(self.datepickerTarget).flatpickr({
      onChange: function() {
        $(self.datepickerTarget)
          .closest('form')
          .trigger('submit');
      }
    });
  }
}
