import {Controller} from "stimulus";

// This is geared specifically toward the async updates made by the editable table component
export default class extends Controller {
  static values = {url: String, targetContainer: String};

  connect() {
    let self = this;
    document.querySelectorAll(self.targetContainerValue)
      .forEach(element => {
        element.querySelectorAll("a[data-action='save']").forEach(saveAction => {
          saveAction.addEventListener("click", self.swapSelf.bind(self))
        });
      });
  }

  swapSelf() {
    let self = this;
    $(self.element).html("<div class='lead text-center'>Loading...</div>")
    $.debounce(500, () => {
      fetch(self.urlValue, {headers: {"Content-Type": "text/html"}})
        .then(response => {
          response.text().then(card => {
            $(self.element).html(card);
          });
        });
    })();
  }
}
