// Controls the site exception form in the admin console

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "datesExplanation", "watch", "pickupProp" ];
  static values = { changed: Boolean, currentSiteType: String, pickupSiteTypes: Array };

  connect() {
    // Watch all inputs inside the watch target for a change
    $(this.watchTarget).on("change", this.updateDateDescription.bind(this));

    this.siteTypeChanged({ target: { value: this.currentSiteTypeValue } });
  }

  updateDateDescription() {
    if (this.changedValue) {
      return;
    }

    if (this.hasDatesExplanationTarget) {
      $(this.datesExplanationTarget).html(this.replacementMessage());
    }

    this.changedValue = true;
  }

  siteTypeChanged(event) {
    if (this.pickupSiteTypesValue.includes(event.target.value)) {
      this.show(this.pickupPropTargets);
    } else {
      this.hide(this.pickupPropTargets);
    }
  }

  // private

  replacementMessage() {
    return `
<div>
  Click Update below to see the updated explanation
</div>
    `;
  }

  show(targets) {
    targets.forEach(target => target.classList.remove("hidden"));
  }

  hide(targets) {
    targets.forEach(target => target.classList.add("hidden"));
  }
}
